
import "./SignIn.scss";
import React, { useState } from 'react';
import { PasswordForgetLink } from 'components/firebase/PasswordForget';
import { useFirebase } from 'components/firebase/Firebase';
import SignUpPage from './SignUp';


const SignPage = () => {
  const [signIn, setSignIn] = useState(true)

  return (
    <div id="signpage">
      <div>
        <div>
          <button id="login" className={signIn ? 'active' : 'inactive'} onClick={() => setSignIn(true)}>Login</button>
          <button id="signup" className={signIn ? 'inactive' : 'active'} onClick={() => setSignIn(false)}>Signup</button>
        </div>

        <div id="divider">
          <div id="activedivider" className={signIn ? 'left' : 'right'}> </div>
        </div>
      </div>

      <div>{signIn ? <SignInForm /> : <SignUpPage/>}</div>
    </div>
  )
}

export default SignPage;


const SignInForm = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)
  const [password, setPassword] = useState("")

  const firebase = useFirebase()

  const invalid = password==='' || email.trim()===''

  const onSubmit = event => {
    event.preventDefault();

    if(!invalid) {
      firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        setEmail("")
        setError(null)
        setPassword("")
        //you do not have to manually redirect here, the LoggedOutRoute component handles this for you
      })
      .catch(setError);
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div><label>Email</label></div>
      <input
        className="form-control"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />

      <label>Password</label>

      <input
        className="form-control"
        name="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        type="password"
      />

      <PasswordForgetLink/>

      <div className="centering">
        <button id="teal" disabled={invalid} type="submit">
          Sign In
        </button>
      </div>
      {error && <p>{error.message}</p>}
    </form>
  );
}

